<template>
 
      <v-select    v-model="selectedYears"  label="text" :options="option"></v-select>
   
</template>
<script>
export default {
    data(){
        return{
            selectedYears:"Tahun 2022",
             option: [
        { value: '2021', text: "Tahun 2021" },
        { value: "2022", text: "Tahun 2022" },
        { value: "2023", text: "Tahun 2023" }
                ],
        }
    }
}
</script>